import dayjs from "dayjs";
import moment from "moment";


export const dateFormate = (date, format = "DD/MM/YYYY") => {
    let a = !!date ? moment(date).format(format) : 'N/A';
    return a;
}

export const formateDateDayjs = (date) => {
    if(!date) return 'N/A'

    return dayjs(date).toDate().toLocaleDateString()
}

export const formateTimeDayjs = (date) => {
    if(!date) return 'N/A'
    return dayjs(date).toDate().toLocaleTimeString()
}