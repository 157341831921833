import { Box, HStack, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { svg } from "../../../assets/svg";

const CountHeader = ({
  data,
  statusTitle,
  leadCount,
  module,
  color,
  onSelectAll,
  onDeleteClick,
  selectedId,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const idSelected = selectedId?.length > 0 ? true : false;
  const handleCheckboxClick = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    const selectedIds = newCheckedState ? data?.map((item) => item._id) : [];
    if (onSelectAll) {
      onSelectAll({ type: "selectAll", ids: selectedIds });
    }
  };

  useEffect(() => {
    setIsChecked(false);
  }, [statusTitle]);

  console.log("after_delete_selectedId", selectedId);

  return (
    <Box
      bg={"white"}
      py={1}
      borderRadius={8}
      shadow={2}
      border={1}
      borderWidth="0.5px"
      borderColor="#ADADAD"
      display={"flex"}
      whiteSpace={"nowrap"}
      width={"100%"}
      mt={2}
      ml={"6px"}
    >
      <HStack
        cursor={"pointer"}
        fontSize="16px"
        fontWeight="400"
        px={4}
        justifyContent="space-between"
        width={"100%"}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          cursor={"pointer"}
          onClick={handleCheckboxClick}
        >
          <Box
            display={"flex"}
            width={"15px"}
            height={"15px"}
            borderRadius={"5px"}
            borderWidth={"0.5px"}
            bgColor={"#F5F5F5"}
            borderColor={"#ADADAD"}
            cursor={"pointer"}
          >
            {idSelected && (
              <Image
                src={svg.checkIcon}
                alt={"checkIcon"}
                objectFit={"contain"}
              />
            )}
          </Box>
          <Text marginLeft="8px">
            <span style={{ marginRight: "30px" }}>
              {idSelected ? `Unselect All` : `Select All`}
            </span>
            {selectedId?.length > 0 && (
              <span style={{ color: color || "#000" }}>
                {`${
                  selectedId?.length ? selectedId?.length : 0
                } ${module} Selected`}
              </span>
            )}
          </Text>
        </Box>

        <Box>
          <Text>
            <span style={{ color: color || "#000" }}>
              {leadCount} {statusTitle || ""}
            </span>{" "}
            {module}
          </Text>
        </Box>
      </HStack>
    </Box>
  );
};

export default CountHeader;
