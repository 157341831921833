import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const CardHeader = ({
  items = [],
  onChange,
  defaultStatus,
  module,
  containerStyle,
}) => {
  const localStorageKey = `selectedStatus_${module}`; // Create a unique key for each module
  const [active, setActive] = useState(() => {
    // Retrieve the stored status from local storage or fallback to the provided default
    return localStorage.getItem(localStorageKey) || defaultStatus;
  });

  const handleClick = (newValue) => {
    setActive(newValue);
    if (onChange) {
      onChange(newValue);
    }
    // Store the selected status in local storage
    localStorage.setItem(localStorageKey, newValue);
  };

  const showScrollbar = items?.length > 6;

  return (
    <Box
      bg={"white"}
      py={3}
      borderRadius={8}
      shadow={2}
      border={1}
      ml={"6px"}
      borderColor={"gray.50"}
      display={"flex"}
      alignItems={"center"}
      overflowX={showScrollbar ? "auto" : "hidden"}
      whiteSpace={"nowrap"}
      width={"100%"}
      sx={{
        "&::-webkit-scrollbar": {
          width: showScrollbar ? "1px" : "0",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#d0d0d0",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#f1f1f1",
        },
        scrollbarWidth: showScrollbar ? "thin" : "none",
        scrollbarColor: showScrollbar
          ? "#d0d0d0 transparent"
          : "transparent transparent",
        msOverflowStyle: showScrollbar ? "auto" : "none",
        position: "sticky",
        top: 0,
        zIndex: 100,
      }}
      justifyContent={showScrollbar ? "flex-start" : "space-between"}
      {...containerStyle}
    >
      {items?.map((item) => (
        <Box
          key={item?.value}
          onClick={() => handleClick(item?.value)}
          color={item?.value === active ? item?.color : "gray"}
          cursor={"pointer"}
          fontSize="16px"
          fontWeight="400"
          px={4}
          display="inline-block"
          minWidth={showScrollbar ? "auto" : "calc(100% / 6)"}
          textDecoration={item?.value === active ? "underline" : "none"}
          mx={1}
        >
          {item.label}
        </Box>
      ))}
    </Box>
  );
};

export default CardHeader;
