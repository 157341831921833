////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  HStack,
  Box,
  Button,
} from "@chakra-ui/react";
import DropDown from "../../../components/DropDown/DropDown";
import { CustomInput } from "../../../myComponent/CustomInput";
import LoadButton from "../../../components/LoadButton";
import { socialMediaSource } from "../../../utils/menuItemCrm";
import { useGetAgent, useGetAgents } from "../../../pages/Team/useQuery/useQuery";
import { debounce } from "lodash";
import { color } from "../../../consts/color";

const LeadFilterSort = ({
  isOpen,
  onClose,
  onApply,
  initialValues,
  onClear,
}) => {
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [users, setUsers] = useState([]);
  const {
    data,
    fetchNextPage: fetchNextPageAgent,
    hasNextPage: hasNextPageAgent,
    isFetching: isFetchingAgent,
    isLoading: isLoadingAgent,
  } = useGetAgents({
    search: inputValue,
    allUser: true,
  });

  useEffect(() => {
    if (data) {
      const allUsers = data?.pages?.flatMap((page) => page?.data || []);
      setUsers(allUsers);
    }
  }, [data]);


  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues: {
      source: "",
      agentArrFilter:"",
      startCreatedDate: "",
      endCreatedDate: "",
      startUpdatedDate:"",
      endUpdatedDate:"",
      startAssignedDate:"",
      endAssignedDate:"",
      sortOrder: "",
      sortBy:"",
    },
    onSubmit: async (values) => {
      onApply(values);
      onClose();
      setIsLoadingBtn(true);

      // Simulate async operation
      setTimeout(() => {
        setIsLoadingBtn(false);
      }, 1000);
    },
  });

  const debouncedHandleInputChange = useMemo(
    () => debounce((newValue) => {
      setInputValue(newValue);
    }, 1000),
    []
  );

  const handleInputChange = (newValue) => {
    debouncedHandleInputChange(newValue);
  };

  const handleMenuScrollToBottom = (key) => {
   if (key === 'agent') {
      if (hasNextPageAgent) {
        fetchNextPageAgent();
      }
    }
  };
  const isApplyButtonDisabled = !(
    values?.source ||
    values?.agentArrFilter.length > 0 ||
    values?.sortBy ||
    values?.sortOrder ||
    values?.endAssignedDate ||
    values?.endCreatedDate ||
    values?.endUpdatedDate ||
    values?.startAssignedDate ||
    values?.startCreatedDate ||
    values?.startUpdatedDate
  );

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Filter & Sort Options</DrawerHeader>
        <DrawerBody>
        <HStack
            justifyContent={"space-between"}
            mb={6}
            alignItems={"flex-start"}
            flexWrap="wrap"
          >
           <Box
              display={"flex"}
              width={"100%"}
              flexDirection={{ base: "column", md: "row" }}
              justifyContent={"space-between"}
            >
           <DropDown
            label={'Select Users'}
            options={users}
            onInputChange={handleInputChange}
            onMenuScrollToBottom={() => handleMenuScrollToBottom('agent')}
            value={values?.agentArrFilter}
            onChange={(v) => {
                  setFieldValue(
                    "agentArrFilter", v);
                }}
            isLoading={isLoadingAgent || isFetchingAgent}
            getOptionLabel={(option) => `${option?.name} ${option?.lastName || ""}`}
            getOptionValue={(option) => option?._id}
            isMulti={true}
            width={{base:'100%',md:'100%'}}
          />
          </Box>
          </HStack>
          <HStack
            justifyContent={"space-between"}
            mb={6}
            alignItems={"flex-start"}
            flexWrap="wrap"
          >
            <Box
              display={"flex"}
              width={"100%"}
              flexDirection={{ base: "column", md: "row" }}
              justifyContent={"space-between"}
            >
              <DropDown
                label={"Select Source"}
                options={socialMediaSource}
                value={values?.source}
                onChange={(e) => setFieldValue("source", e)}
              />
            </Box>
          </HStack>
          <Box mt={1} display={"flex"} gap={2} mb={4}>
            <CustomInput
              type="date"
              label="Created Start Date"
              value={values?.startCreatedDate}
              onChange={(e) =>
                setFieldValue("startCreatedDate", e.target.value)
              }
              containerStyle={{ width: "50%" }}
            />
            <CustomInput
              type="date"
              label="Created End Date"
              value={values?.endCreatedDate}
              onChange={(e) => setFieldValue("endCreatedDate", e.target.value)}
              containerStyle={{ width: "50%" }}
            />
          </Box>
          <Box mt={1} display={"flex"} gap={2} mb={4}>
            <CustomInput
              type="date"
              label="Updated Start Date"
              value={values?.startUpdatedDate}
              onChange={(e) =>
                setFieldValue("startUpdatedDate", e.target.value)
              }
              containerStyle={{ width: "50%" }}
            />
            <CustomInput
              type="date"
              label="Updated End Date"
              value={values?.endUpdatedDate}
              onChange={(e) => setFieldValue("endUpdatedDate", e.target.value)}
              containerStyle={{ width: "50%" }}
            />
          </Box>
          <Box mt={1} display={"flex"} gap={2} mb={4}>
            <CustomInput
              type="date"
              label="Assigned Start Date"
              value={values?.startAssignedDate}
              onChange={(e) =>
                setFieldValue("startAssignedDate", e.target.value)
              }
              containerStyle={{ width: "50%" }}
            />
            <CustomInput
              type="date"
              label="Assigned End Date"
              value={values?.endAssignedDate}
              onChange={(e) => setFieldValue("endAssignedDate", e.target.value)}
              containerStyle={{ width: "50%" }}
            />
          </Box>
          <Box mt={1} display={"flex"} gap={2} mb={4}>
            <DropDown
              label={"Sort By"}
              options={[
                { value: "name", label: "Name" },
                { value: "assignedDate", label: "Assigned Date" },
              ]}
              value={values?.sortBy}
              onChange={(e) => setFieldValue("sortBy", e)}
            />
          </Box>
          <Box mt={1} display={"flex"} gap={2} mb={4}>
            <DropDown
              label={"Select Order"}
              options={[
                { value: "1", label: "Ascending" },
                { value: "-1", label: "Descending" },
              ]}
              value={values?.sortOrder}
              onChange={(e) => setFieldValue("sortOrder", e)}
              isDisabled={!values?.sortBy}
            />
          </Box>
        </DrawerBody>
        <DrawerFooter>
          <LoadButton
            bgColor={"rgba(208, 131, 127, 1)"}
            color={"#fff"}
            padding={"5px 20px"}
            onClick={() => {
              resetForm();
              handleSubmit();
            }}
            mr={3}
          >
            Clear All
          </LoadButton>
          <LoadButton
            bgColor={color.primaryBtn}
            color={"#fff"}
            padding={"5px 20px"}
            onClick={handleSubmit}
            isDisabled={isApplyButtonDisabled} 
          >
            Apply
          </LoadButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default LeadFilterSort;
