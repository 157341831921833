import { Box, Button, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { color } from "../consts/color";
import { CustomCheckBox } from "./CustomCheckBox";
import { svg } from "../assets/svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useProfileQuery } from "../Queries/auth/useProfileQuery";
import { adminArr } from "../utils/menuItems";

const shadow = `rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px`;

const StyledButton = ({ icon, label, onClick, hoverColor, isDisabled }) => (
  <Button
    leftIcon={<img src={icon} alt={label} />}
    size="sm"
    height="30px"
    width="145px"
    border="1px"
    bg="#F5F5F5"
    color="#000"
    borderRadius="0.5px"
    borderColor="#ADADAD"
    borderBottom="none"
    borderLeft="none"
    onClick={!isDisabled ? onClick : undefined}
    className="dynamic-button"
    isDisabled={isDisabled}
    // _hover={{
    //   bg: hoverColor.bg,
    //   color: hoverColor.text,
    //   borderColor: hoverColor.border,
    // }}
    _hover={
      !isDisabled
        ? {
            bg: hoverColor.bg,
            color: hoverColor.text,
            borderColor: hoverColor.border,
          }
        : {
            bg: "#F5F5F5", // default background when disabled
            color: "#A0A0A0", // default color when disabled
            borderColor: "#ADADAD", // default border when disabled
          }
    }
  >
    {label}
  </Button>
);

export const BoarderBox = ({
  item,
  count,
  meetItem,
  children,
  containerStyle,
  onClickCheckbox,
  onClickBox,
  onClickPending,
  onClickApprove,
  onClickReject,
  onClickEdit,
  onClickDelete,
  onClickDeactivate,
  onClickActivate,
  onClickUpdate,
  onClickConversations,
  onClickMeetings,
  onClickCall,
  onClickNotes,
  onStatusChange,
  onReschedule,
  showPending,
  showApprove,
  showReject,
  showEdit,
  showDelete,
  showDeactivate,
  showActivate,
  showUpdate,
  showCalls,
  showConversation,
  showNotes,
  ShowMeetings,
  showStatusChange,
  showReschedule,
}) => {
  const [clickedButtons, setClickedButtons] = useState({
    pending: false,
    update: false,
    approve: false,
    reject: false,
    edit: false,
    delete: false,
    deactivate: false,
    activate: false,
    calls: false,
    conversation: false,
    meetings: false,
  });

  const [checkboxStates, setCheckboxStates] = useState({});
  const [selectedIds, setSelectedIds] = useState({});
  let selectAllChecked;
  if (item?._id) {
    selectAllChecked = count.includes(item?._id);
  }
  const handleClickCheckbox = (item) => {
    if (!item || !item._id) return;

    setSelectedIds((prevIds) => {
      const currentIds = Array.isArray(prevIds) ? prevIds : [];

      const isChecked = checkboxStates[item._id] || false;
      console.log("currentIds", isChecked);

      const newState = !isChecked;
      setCheckboxStates((prev) => ({
        ...prev,
        [item._id]: newState,
      }));

      let newSelectedIds;
      if (newState) {
        newSelectedIds = [...currentIds, item._id];
      } else {
        newSelectedIds = currentIds;
      }
      onClickCheckbox &&
        onClickCheckbox({ type: "singleSelect", ids: newSelectedIds });

      return newSelectedIds;
    });
  };

  const handleClickBox = () => {
    onClickBox && onClickBox();
  };

  const { data: profileData } = useProfileQuery();

  const itemId = item?.agent?.agentId;
  const authId = profileData?._id;
  const meetButtom = meetItem?.agent?.agentId;
  const isNotesDisabled = authId !== itemId && authId !== meetButtom;
  const isSupSubAdmin = adminArr?.includes(profileData?.role);
  const handleButtonClick = (buttonKey, onClick, event) => {
    event?.stopPropagation();
    setClickedButtons((prevState) => ({
      ...prevState,
      [buttonKey]: true,
    }));
    onClick && onClick(event);
  };

  return (
    <Box
      width="100%"
      borderRadius="6px"
      borderWidth="0.5px"
      borderColor="#ADADAD"
      bgColor={color.white}
      style={containerStyle}
      marginStart={"5px"}
      onClick={handleClickBox}
      cursor={`url(${svg.cursor}) 5 5, auto`}
    >
      <Box display="flex" flexDirection="column">
        <Box>
          {!onClickCheckbox ? (
            children
          ) : (
            <Box bgColor={color.white} display="flex" borderRadius="6px">
              <Box padding="15px 0px 0px 15px">
                <CustomCheckBox
                  isChecked={selectAllChecked}
                  onClickCheck={() => handleClickCheckbox(item)}
                />
              </Box>
              <Box display="flex" width="100%">
                {children}
              </Box>
            </Box>
          )}
        </Box>

        {/* Buttons Container */}
        <Box>
          <Stack
            direction="row"
            spacing={0}
            background="#fff"
            borderBottomLeftRadius={"5px"}
            borderBottomRightRadius={"5px"}
            className="button-stack"
          >
            {showPending && (
              <StyledButton
                icon={svg.Pending}
                label="Pending"
                onClick={(event) =>
                  handleButtonClick("pending", onClickPending, event)
                }
                hoverColor={{ bg: "#E0E0E0", text: "#333", border: "#9E9E9E" }}
                isDisabled={clickedButtons?.pending}
              />
            )}
            {showUpdate && (
              <StyledButton
                icon={svg.TeamUpdateIcons}
                label="Update"
                onClick={(event) =>
                  handleButtonClick("update", onClickUpdate, event)
                }
                // onClick={onClickUpdate}
                hoverColor={{ bg: "#D0F0C0", text: "#000", border: "#B0D6A8" }}
                isDisabled={clickedButtons?.update}
              />
            )}
            {showApprove && (
              <StyledButton
                icon={svg.Approve}
                label="Approve"
                onClick={(event) =>
                  handleButtonClick("approve", onClickApprove, event)
                }
                // onClick={onClickApprove}
                hoverColor={{
                  bg: "#DFF0D8",
                  text: "#3C763D",
                  border: "#D6E9C6",
                }}
                isDisabled={clickedButtons.approve}
              />
            )}
            {showReject && (
              <StyledButton
                icon={svg.Reject}
                label="Reject"
                onClick={(event) =>
                  handleButtonClick("reject", onClickReject, event)
                }
                // onClick={onClickReject}
                hoverColor={{
                  bg: "#F2DEDE",
                  text: "#A94442",
                  border: "#EED3D7",
                }}
                isDisabled={clickedButtons?.reject}
              />
            )}
            {showEdit && (
              <StyledButton
                icon={svg.Edit}
                label="Edit"
                onClick={(event) =>
                  handleButtonClick("edit", onClickEdit, event)
                }
                // onClick={onClickEdit}
                hoverColor={{
                  bg: "#D9EDF7",
                  text: "#31708F",
                  border: "#BCE8F1",
                }}
                isDisabled={clickedButtons?.edit}
              />
            )}
            {isSupSubAdmin && showDelete && (
              <StyledButton
                icon={svg.DeleteIcons}
                label="Delete"
                onClick={(event) =>
                  handleButtonClick("delete", onClickDelete, event)
                }
                // onClick={onClickDelete}
                hoverColor={{
                  bg: "#FBE3E4",
                  text: "#D43F3A",
                  border: "#F5C6C6",
                }}
                // isDisabled={!isSupSubAdmin}
                isDisabled={clickedButtons?.delete}
              />
            )}
            {showDeactivate && (
              <StyledButton
                icon={svg.Deactivate}
                label="Deactivate"
                onClick={(event) =>
                  handleButtonClick("deactivate", onClickDeactivate, event)
                }
                // onClick={onClickDeactivate}
                hoverColor={{
                  bg: "#FDF5E6",
                  text: "#E8AE5A",
                  border: "#F5D6A6",
                }}
                isDisabled={clickedButtons?.deactivate}
              />
            )}
            {showActivate && (
              <StyledButton
                icon={svg.Activate}
                label="Activate"
                onClick={(event) =>
                  handleButtonClick("activate", onClickActivate, event)
                }
                // onClick={onClickActivate}
                hoverColor={{
                  bg: "#E6F9F2",
                  text: "#2D6A4F",
                  border: "#B5E4D8",
                }}
                isDisabled={clickedButtons?.activate}
              />
            )}
            {showCalls && (
              <StyledButton
                icon={svg.calls}
                label="Call"
                onClick={onClickCall}
                hoverColor={{
                  bg: "#E0F7FA",
                  text: "#00838F",
                  border: "#B2EBF2",
                }}
              />
            )}
            {showConversation && (
              <StyledButton
                icon={svg.conversation}
                label="Conversations"
                onClick={(event) =>
                  handleButtonClick("conversation", onClickConversations, event)
                }
                hoverColor={{
                  bg: "#E1BEE7",
                  text: "#6A1B9A",
                  border: "#CE93D8",
                }}
                isDisabled={clickedButtons?.conversation}
              />
            )}
            {showNotes && (
              <StyledButton
                icon={svg.notes}
                label="Notes"
                onClick={onClickNotes}
                hoverColor={{
                  bg: "#F3E5F5",
                  text: "#6D28D9",
                  border: "#E1BEE7",
                }}
                isDisabled={!isSupSubAdmin && isNotesDisabled}
              />
            )}
            {/*  */}
            {ShowMeetings && (
              <StyledButton
                icon={svg.meetings}
                label="Meeting"
                onClick={(event) =>
                  handleButtonClick("meetings", onClickMeetings, event)
                }
                // onClick={onClickMeetings}
                hoverColor={{
                  bg: "#E8F5E9",
                  text: "#2E7D32",
                  border: "#C8E6C9",
                }}
                isDisabled={clickedButtons?.meetings}
              />
            )}
            {showReschedule && (
              <StyledButton
                icon={svg.statusChange}
                label="Reschedule"
                onClick={onReschedule}
                hoverColor={{
                  bg: "#FFE0B2",
                  text: "#F57C00",
                  border: "#FFCC80",
                }}
                isDisabled={!isSupSubAdmin && isNotesDisabled}
              />
            )}
            {showStatusChange && (
              <StyledButton
                icon={svg.statusChange}
                label="Status Change"
                onClick={onStatusChange}
                hoverColor={{
                  bg: "#FFEBEE",
                  text: "#C62828",
                  border: "#FFCDD2",
                }}
                isDisabled={!isSupSubAdmin && isNotesDisabled}
              />
            )}
          </Stack>
        </Box>
      </Box>

      {/* Add styles for buttons */}
      <style jsx>{`
        .button-stack > .dynamic-button:first-child:hover {
          border-bottom-left-radius: 5px !important;
        }
        .button-stack > .dynamic-button:first-child {
          border-bottom-left-radius: 5px !important;
        }
        .button-stack > .dynamic-button:not(:first-child):hover {
          border-bottom-left-radius: 0px !important;
        }

        .dynamic-button {
          border-bottom-left-radius: 0px;
        }
      `}</style>
    </Box>
  );
};
