import { TbTargetArrow } from "react-icons/tb";
import { FaUser } from "react-icons/fa";
import { MdHub, MdOutlineStars } from "react-icons/md";
import { FaCalendarCheck } from "react-icons/fa";
import { TiDocumentText } from "react-icons/ti";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { svg } from "../../src/assets/bookings.svg";
import dashboard from "../assets/dashboard.svg";
import leadsFill from "../assets/leads.svg";
import agents from "../assets/agents.svg";
import meetingSide from "../assets/meetingSide.svg";
import bookings from "../assets/bookings.svg";
import calendar from "../assets/calendar.svg";
import conversationSide from "../assets/conversationSide.svg";
import reports from "../assets/reports.svg";
import marketingSide from "../assets/marketing.svg";
import contacts from "../assets/contacts.svg";
import callLogs from "../assets/contacts.svg";
import payments from "../assets/payments.svg";
import teams from "../assets/teams.svg";
import attendance from "../assets/attendance.svg";
import leaves from "../assets/leaves.svg";
import agentFill from "../assets/agentFill.svg";
import bookingFill from "../assets/bookingFill.svg";
import calederFill from "../assets/calenderFill.svg";
import conversationFill from "../assets/conversationFill.svg";
import dashboardFill from "../assets/dashboardFill.svg";
import leads from "../assets/leadsFill.svg";
import marketingFill from "../assets/marketingFill.svg";
import contactsFill from "../assets/contactsFill.svg";
import callLogsFill from "../assets/contactsFill.svg";
import paymentsFill from "../assets/paymentsFill.svg";
import reportsFill from "../assets/reportsFill.svg";
import meetingFill from "../assets/meetingsFill.svg";
import teamsFill from "../assets/teamsFill.svg";
import attendanceFill from "../assets/attendanceFill.svg";
import leavesFill from "../assets/leavesFill.svg";
import medias from "../assets/medias.svg";
import mediasFill from "../assets/mediasFill.svg";
import logoutbtn from "../assets/logout.svg";
import charity from "../assets/charity1.svg";
import charityFill from "../assets/charity.svg";
import automation from "../assets/automation.svg";
import automationFill from "../assets/automationFill.svg";

export const menuItems = [
  {
    id: "dashboard",
    label: "Dashboard",
    href: "/dashboard",
    icon: dashboard,
    fillIcon: dashboardFill,
  },
  {
    label: "User",
    href: "/users",
    icon: agents,
    fillIcon: agentFill,
  },
  {
    label: "Teams",
    href: "/teams",
    icon: teams,
    fillIcon: teamsFill,
  },
  {
    label: "Attendance",
    href: "/attendance",
    icon: attendance,
    fillIcon: attendanceFill,
  },
  {
    id: "leaves",
    label: "Leaves",
    href: "/leaves",
    icon: leaves,
    fillIcon: leavesFill,
  },
];

export const crmMenuItems = [
  {
    id: "dashboard",
    label: "Dashboard",
    href: "/",
    icon: dashboard,
    fillIcon: dashboardFill,
  },
  {
    id: "leads",
    label: "Leads",
    href: "/lead",
    icon: leads,
    fillIcon: leadsFill,
  },
  {
    id: "agents",
    label: "User", // Change label here as well
    href: "/agents",
    icon: agents,
    fillIcon: agentFill,
  },
  {
    id: "meetings",
    label: "Meetings",
    href: "/meetings",
    icon: meetingSide,
    fillIcon: meetingFill,
  },
  {
    id: "callLogs",
    label: "Call Logs",
    href: "/calllogs",
    icon: callLogs,
    fillIcon: callLogsFill,
  },
  {
    id: "booking",
    label: "Booking",
    href: "/booking",
    icon: bookings,
    fillIcon: bookingFill,
  },
  {
    id: "calendar",
    label: "Calendar",
    href: "/calendar",
    icon: calendar,
    fillIcon: calederFill,
  },
  {
    id: "conversation",
    label: "Conversation",
    href: "/conversation",
    icon: conversationSide,
    fillIcon: conversationFill,
  },
  {
    id: "reports",
    label: "Reports",
    href: "/reports",
    icon: reports,
    fillIcon: reportsFill,
  },
  {
    id: "marketing",
    label: "Marketing",
    href: "/marketing",
    icon: marketingSide,
    fillIcon: marketingFill,
  },
  {
    id: "contacts",
    label: "Contacts",
    href: "/contacts",
    icon: contacts,
    fillIcon: contactsFill,
  },
  {
    id: "payments",
    label: "Accounts",
    href: "/payments",
    icon: payments,
    fillIcon: paymentsFill,
  },
  {
    id: "charity",
    label: "Charity",
    href: "/charity",
    icon: charity,
    fillIcon: charityFill,
  },
  {
    id: "template",
    label:"Automation",
    href:"/template",
    icon: automation,
    fillIcon: automationFill,
  },
  // {
  //   id: "medias",
  //   label: "Medias",
  //   href: "/medias",
  //   icon: medias,
  //   fillIcon: mediasFill,
  // },
];

export const crmItemsAgents = [
  {
    id: "dashboard",
    label: "Dashboard",
    href: "/",
    icon: dashboard,
    fillIcon: dashboardFill,
  },
  {
    id: "leads",
    label: "Leads",
    href: "/lead",
    icon: leads,
    fillIcon: leadsFill,
  },

  {
    id: "agents",
    label: "Teams",
    href: "/agents",
    icon: teams,
    fillIcon: teamsFill,
  },
  {
    id: "meetings",
    label: "Meetings",
    href: "/meetings",
    icon: meetingSide,
    fillIcon: meetingFill,
  },
  {
    id: "callLogs",
    label: "Call Logs",
    href: "/calllogs",
    icon: callLogs,
    fillIcon: callLogsFill,
  },
  {
    id: "booking",
    label: "Booking",
    href: "/booking",
    icon: bookings,
    fillIcon: bookingFill,
  },
  {
    id: "calendar",
    label: "Calendar",
    href: "/calendar",
    icon: calendar,
    fillIcon: calederFill,
  },
  {
    id: "conversation",
    label: "Conversation",
    href: "/conversation",
    icon: conversationSide,
    fillIcon: conversationFill,
  },
  {
    id: "reports",
    label: "Reports",
    href: "/reports",
    icon: reports,
    fillIcon: reportsFill,
  },
  // {
  //   id: "marketing",
  //   label: "Marketing",
  //   href: "/marketing",
  //   icon: marketingSide,
  //   fillIcon: marketingFill,
  // },
  {
    id: "contacts",
    label: "Contacts",
    href: "/contacts",
    icon: contacts,
    fillIcon: contactsFill,
  }
 
  // {
  //   id: "payments",
  //   label: "Accounts",
  //   href: "/payments",
  //   icon: payments,
  //   fillIcon: paymentsFill,
  // },
  // {
  //   id: "medias",
  //   label: "Medias",
  //   href: "/medias",
  //   icon: medias,
  //   fillIcon: mediasFill,
  // },
];

export const menuItemsAgent = [
  // {
  //   id: "dashboard",
  //   label: "Dashboard",
  //   href: "/dashboard",
  //   icon: dashboard,
  //   fillIcon: dashboardFill,
  // },
  // {
  //   label: "Teams",
  //   href: "/teams",
  //   icon: teams,
  //   fillIcon: teamsFill,
  // },
  {
    label: "Leaves",
    href: "/leaves/my_leave",
    icon: leaves,
    fillIcon: leavesFill,
  },
  {
    label: "Attendance",
    href: "/attendance",
    icon: attendance,
    fillIcon: attendanceFill,
  },
  // {
  //   label: "Dashboard",
  //   href: "/dashboard",
  //   icon: <TbTargetArrow size={"1.4rem"} />,
  // },
  // {
  //   label: "Teams",
  //   href: "/teams",
  //   icon: <MdHub size={"1.4rem"} />,
  // },
  // {
  //   label: "Leaves",
  //   href: "/leaves",
  //   icon: <FaCalendarCheck size={"1.4rem"} />,
  // },
  // {
  //   label: "Attendance",
  //   href: "/attendance",
  //   icon: <TiDocumentText size={"1.4rem"} />,
  // },
];

export const userHeaderItems = [
  {
    label: "New",
    value: "new",
    color: "#E9A80A",
  },
  {
    label: "Draft",
    value: "draft",
    color: "#FF8A00",
  },
  {
    label: "Pending",
    value: "pending",
    color: "#0095FF",
  },
  {
    label: "Approved / Active",
    value: "approved",
    color: "#4ABC04",
  },
  {
    label: "Rejected",
    value: "rejected",
    color: "#FF0000",
  },
  {
    label: "Deactivated",
    value: "deactive",
    color: "#740707",
  },
];

export const attendaceItems = [
  {
    label: "Issues Raised",
    value: "isIssue",
    color: "#740707",
  },
  {
    label: "Present",
    value: "present",
    color: "#4ABC04",
  },
  {
    label: "Absent",
    value: "absent",
    color: "#FF0000",
  },
  {
    label: "On Leave",
    value: "leave",
    color: "#0095FF",
  },
  {
    label: "Half Day",
    value: "halfDay",
    color: "#FF8A00",
  },
  {
    label: "Resolved",
    value: "resolved",
    color: "#FF8A00",
  },
];

export const campaignHeaders = [
  {
    label:"Minutes",
    value:"m",
  },
  {
    label:"Hour",
    value:"h",
  },
  {
    label:"Days",
    value:"d",
  },
  {
    label:"Week",
    value:"w",
  },
  {
    label:"Month",
    value:"M",
  },
]

export const userNotificationItems = [
  {
    label: "New",
    value: "unseen",
    color: "#E9A80A",
  },
  {
    label: "Read",
    value: "seen",
    color: "#FF8A00",
  },
];

export const charityHeaders = [
  {
    label: "Collections",
    value: "collections",
    color: "rgba(154, 77, 73, 1)",
  },
  {
    label: "Distribution",
    value: "distribution",
    color: "rgba(154, 77, 73, 1)",
  },
];

export const automationHeader = [
  {
    label: "Template",
    value: "template",
    color: "rgba(154, 77, 73, 1)",
  },
  {
    label: "Campaign",
    value: "campaign",
    color: "rgba(154, 77, 73, 1)",
  }
]

export const paymentHeader = [
  {
    label: "Invoice",
    value: "invoice",
    color: "#9A4D49",
  },
  {
    label: "Accounts",
    value: "payments",
    color: "#9A4D49",
  },
];

export const leaveHeaderItems = [
  {
    label: "New",
    value: "new",
    color: "#E9A80A",
  },
  {
    label: "On Hold",
    value: "onHold",
    color: "#FF8A00",
  },
  {
    label: "Revise",
    value: "revise",
    color: "#0095FF",
  },
  {
    label: "Approved",
    value: "approved",
    color: "#4ABC04",
  },
  {
    label: "Rejected",
    value: "rejected",
    color: "#FF0000",
  },
  {
    label: "Cancelled",
    value: "cancelled",
    color: "#FF0000",
  },
];

export const attendanceHeaderItems = [
  {
    label: "Issues Raised",
    value: "issuesRaised",
    color: "#740707",
  },
  {
    label: "Present",
    value: "present",
    color: "#4ABC04",
  },
  {
    label: "Absent",
    value: "absent",
    color: "#FF0000",
  },
  {
    label: "On Leave",
    value: "onLeave",
    color: "#0095FF",
  },
  {
    label: "Half Day",
    value: "haffDay",
    color: "#FF8A00",
  },
];

export const attStatusItems = [
  {
    label: "Present",
    value: "present",
  },
  {
    label: "Absent",
    value: "absent",
  },
  {
    label: "Leave",
    value: "leave",
  },
  {
    label: "Half Day",
    value: "halfDay",
  },
];

export const attStatusItemsObj = {
  present: "Present",
  absent: "Absent",
  leave: "Leave",
  halfDay: "Half Day",
};

export const userStatusObj = {
  approve: "approved",
  reject: "rejected",
  deactive: "deactive",
  active: "active",
  pending: "pending",
  cancelled: "cancelled",
  revise: "revise",
  onHold: "onHold",
};

export const leaveTypes = [
  { value: "paid", label: "Paid" },
  { value: "unpaid", label: "Un Paid" },
];

export const userRoles = [
  // { value: "sup_admin", label: "Super Admin" },
  { value: "sub_admin", label: "Sub Admin" },
  { value: "manager", label: "Manager" },
  { value: "teamLead", label: "Team Lead" },
  { value: "agent", label: "Agent" },
  { value: "hr", label: "HR" },
];

export const userRolesObj = {
  sub_admin: "Sub Admin",
  manager: "Manager",
  teamLead: "Team Lead",
  agent: "Agent",
  sup_admin: "Super Admin",
  hr: "HR",
};

export const adminArr = ["sup_admin", "sub_admin"];
export const rolesNotAgentTeam = ["sup_admin", "sub_admin", "manager"];
export const rolesNotTeamLead = [
  "sup_admin",
  "sub_admin",
  "manager",
  "teamLead",
];
export const rolesNotHr = [
  "sup_admin",
  "sub_admin",
  "manager",
  "teamLead",
  "agent",
];
export const rolesNotagents = [
  "sup_admin",
  "sub_admin",
  "manager",
  "teamLead",
  "hr",
];
export const leaveAccessRole = ["sup_admin", "sub_admin", "hr"];
export const reportAccessRole = ["sup_admin", "sub_admin", "manager", "hr"];

export const generateYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const options = [];

  for (let i = currentYear - 10; i <= currentYear + 10; i++) {
    options.push({ value: i.toString(), label: i.toString() });
  }

  return options;
};

export const regionOptions = [
  { value: "north-america", label: "North America" },
  { value: "europe", label: "Europe" },
  { value: "asia", label: "Asia" },
  { value: "south-america", label: "South America" },
  { value: "africa", label: "Africa" },
  { value: "oceania", label: "Oceania" },
  // Add more regions as needed
];

export const monthOptions = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];
