import React, { useEffect, useMemo, useState } from "react";
import { Grid, GridItem, HStack } from "@chakra-ui/react";
import { useFormik } from "formik";
import ModalBlur from "../../../components/Leave/ModalBlur";
import { CustomText } from "../../../myComponent/CustomText";
import { CustomBtn } from "../../../myComponent/CustomBtn";
import { useNavigate } from "react-router-dom";
import { addLeadAssignAgents } from "../../../apiCRM/leadApi";
import { useTeamQuery } from "../../../pages/Team/useQuery/useQuery";
import { useUserQuery } from "../../../Queries/user/userUserQuery";
import DropDown from "../../../components/DropDown/DropDown";
import { debounce } from "lodash";

const AssignLead = ({ isOpen, onClose, refetch, data: id }) => {
  const navigate = useNavigate();
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [users, setUsers] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const {
    data: allTeams,
    fetchNextPage: fetchNextPageTeam,
    hasNextPage: hasNextPageTeam,
    isFetching: isFetchingTeam,
    isLoading: isLoadingTeam,
  } = useTeamQuery({ search: inputValue });
  const { data, fetchNextPage, hasNextPage, isLoading, isFetching } =
    useUserQuery({ status: "approved", search: inputValue });

  useEffect(() => {
    if (data) {
      const allUsers = data?.pages?.flatMap((page) => page?.data || []);
      setUsers(allUsers);
    }
  }, [data]);
  const debouncedHandleInputChange = useMemo(
    () =>
      debounce((newValue) => {
        setInputValue(newValue);
        refetch();
      }, 1000),
    []
  );

  const handleInputChange = (newValue) => {
    debouncedHandleInputChange(newValue);
  };
  const { values, touched, errors, handleSubmit, setFieldValue, setValues } =
    useFormik({
      initialValues: {
        agentIdArr: [],
        teamId: "",
      },
      enableReinitialize: true,
      onSubmit: async (values) => {
        setIsLoadingBtn(true);
        const sendData = {
          leadIdArr: id,
          agentIdArr: values?.agentIdArr?.map((agent) => agent?._id),
          teamId: values?.teamId?._id || "",
        };
        try {
          let res = await addLeadAssignAgents({
            sendData,
          });
          onClose();
          navigate("/lead");
          refetch();
        } catch (error) {
          console.error("Error submitting form:", error);
        } finally {
          setIsLoadingBtn(false);
        }
      },
    });

  const handleMenuScrollToBottom = (key) => {
    if (key === "team") {
      if (hasNextPageTeam) {
        fetchNextPageTeam();
      }
    } else if (key === "agent") {
      if (hasNextPage) {
        fetchNextPage();
      }
    }
  };

  return (
    <ModalBlur
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <CustomBtn
          title={"Assign"}
          onClick={handleSubmit}
          isLoading={isLoadingBtn}
          //   isDisabled={isButtonDisabled}
          containerStyle={{
            width: "90px",
            alignSelf: "center",
          }}
        />
      }
      size="xl"
      maxWidth={{ base: "260px", md: "400px" }}
      minWidth={{ base: "350px", md: "inherit" }}
      maxHeight="80vh"
    >
      <Grid templateColumns={{ base: "1fr" }}>
        <GridItem>
          <CustomText
            fontSize="1.7rem"
            textAlign="center"
            my={4}
            fontWeight="bold"
          >
            Assign Lead
          </CustomText>
        </GridItem>
        <HStack
          justifyContent={"space-between"}
          mb={5}
          display="flex"
          alignItems="center"
          flexWrap="wrap"
        >
          <DropDown
            label={"Select Teams"}
            name="teamId"
            value={values?.teamId}
            options={allTeams || []}
            onInputChange={handleInputChange}
            width={{ base: "100%", md: "100%" }}
            onMenuScrollToBottom={() => handleMenuScrollToBottom("team")}
            isLoading={isLoadingTeam || isFetchingTeam}
            getOptionLabel={(option) => option?.teamName}
            getOptionValue={(option) => option?._id}
            onChange={(v) => {
              setValues({
                ...values,
                teamId: v,
                agentIdArr: [], // Clear selected agents when team is selected
              });
            }}
            // error={touched?.teamId && errors?.teamId}
          />
        </HStack>
        <CustomText
          mt={{ base: "0rem", md: "0rem" }}
          style={{ textAlign: "center" }}
          fontSize={16}
          marginBottom={{ base: "0rem", md: "inherit" }}
        >
          OR
        </CustomText>
        <DropDown
          label={"Select Individual Users"}
          name="agentIdArr"
          value={values?.agentIdArr}
          isMulti={true}
          options={users || []}
          onInputChange={handleInputChange}
          width={{ base: "100%", md: "100%" }}
          onMenuScrollToBottom={() => handleMenuScrollToBottom("agent")}
          onChange={(v) => {
            setValues({
              ...values,
              agentIdArr: v,
              teamId: "",
            });
          }}
          isLoading={isLoading || isFetching}
          getOptionLabel={(option) =>
            `${option?.name} ${option.lastName || ""}`
          }
          getOptionValue={(option) => option?._id}
          // error={touched?.agentIdArr && errors?.agentIdArr}
        />
      </Grid>
    </ModalBlur>
  );
};

export default AssignLead;
